<template>
    <div>
        <div class="text-xs-right">
            <v-btn class="blue" small round color="primary" :to="{name: 'campaigns.new_campaign'}" >
                <v-icon left class="fas fa-plus " > </v-icon>
                <span> Create New Campaign</span>
            </v-btn>
        </div>
        <v-card color="white" style="" v-for="item in activeCampaigns"
            :key="item.key"
            :to="item.link" 
        >
            <v-card-text style="font-size: 12px; cursor: pointer">
                <h2 class="blue--text">{{item.title}}</h2>
                <span><b>Creation Date:</b> {{item.creation_date}}</span>
                <v-spacer></v-spacer>
                <span><b>Objective:</b>  {{item.objective}}</span>
            </v-card-text>
        <v-divider></v-divider>
        </v-card>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                activeCampaigns: [
                    {
                        title: 'New Telephone',
                        creation_date: '02/02/2022 at 10:43',
                        objective: '30,000 FCFA by the 13/04/2022',
                        link: '/savings/campaigns/1',
                        key: 'xHI654Gik'
                    },
                    {
                        title: 'Christmas',
                        creation_date: '02/02/2022 at 10:43',
                        objective: 'By the 23/12/2022',
                        link: '/savings/campaigns/2',
                        key: 'x#$fg6k'
                    },
                    {
                        title: 'Health Campaign',
                        creation_date: '12/06/2021 at 10:43',
                        objective: '13,500 FCFA',
                        link: '/savings/campaigns/3',
                        key: 'xHI6GfSD'
                    }
                ],
            }
        },

    }
</script>
